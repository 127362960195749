<div class="container-fluid h-100 d-flex flex-column">
  <div class="row justify-content-center px-4">
    <form class="login-form d-flex align-items-center justify-content-center mb-5" (ngSubmit)="login(loginForm)"
      [formGroup]="loginForm" novalidate>
      <mat-card class="w-100">
        <mat-card-content class="d-flex flex-column">
          <div class="d-flex justify-content-center">
            <img src="/assets/icons/icon-512x512.png" alt="Astreinte Réseau" width="128" height="128" class="app-logo">
          </div>
          <h2>{{'login.title' | translate}}</h2>
          <mat-form-field class="mb-3">
            <mat-label>{{'login.email'|translate}}</mat-label>
            <input matInput type="email" formControlName="email" />
            <mat-icon matSuffix>perm_identity</mat-icon>
          </mat-form-field>
          <mat-form-field class="mb-3">
            <mat-label>{{'login.password'|translate}}</mat-label>
            <input matInput type="password" formControlName="password" />
            <mat-icon matSuffix>lock</mat-icon>
          </mat-form-field>
          <button mat-raised-button class="align-self-start px-3 py-1" color="primary" [disabled]="!loginForm.valid">
            <mat-icon>login</mat-icon>
            {{'login.connect' | translate}}
          </button>
          <ng-container *ngIf="(getOAuth2Providers() | async).length">
            <hr>
            <ng-container *ngFor="let provider of getOAuth2Providers() | async">
              <button mat-raised-button class="align-self-center px-3 py-1" color="primary"
                *ngIf="provider.id == 'entraid-bo'" (click)="loginWithOAuth2(provider.url)">
                <mat-icon>login</mat-icon>
                {{'login.connect_via' | translate:{provider: provider.label} }}
              </button>
            </ng-container>
          </ng-container>
        </mat-card-content>
      </mat-card>
    </form>
  </div>
</div>
